.imageInput {
  display: none;
}

.inputContainer {
  height: 150px;
  max-width: 225px;
  border-radius: 5px;
  overflow: hidden;
}

.uploadBtnContainer {
  z-index: 100;
}

.mystyle{
  color:#b8b8be
}
