.singleOtpInputField {
  min-width: 55px;
  min-height: 55px;
}

@media only screen and (max-width: 600px) {
  .singleOtpInputField {
    min-width: 35px;
    min-height: 35px;
  }
}
