@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff !important;
}

.rules-menu-container,
.ar-instructions-help-button,
.helloar-view-in-your-own-space-button {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rules-menus,
.rules-menu-toggle {
  display: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

#sidebar {
  width: 25rem;
  min-height: 100vh;
  overflow: hidden;
}
.sidebarCardCol {
  /* overflow-x: hidden;
  overflow-y: scroll; */
  height: 100vh;
}
.editingCustomizations {
  height: 90vh;
}
.viewerContainer {
  height: 100vh;
  width: calc(100%-25vw);
  transition: all 0.5s ease-in;
  margin-right: 18%;
}
.hello-instructor {
  z-index: 10 !important;
}

#hello-progressbar-container {
  right: -2%;
}

.lds-container {
  display: none !important;
}

select {
  border: none;
  background: rgba(9, 30, 66, 0.04);
  font-size: 14px !important;
  color: #42526e;
  padding: 5px 10px;
}

input[type="text"],
input[type="number"] {
  border: 1px solid #dfe1e6;
  border-radius: 3px;
  background: rgba(9, 30, 66, 0.04);
  font-size: 14px !important;
  color: #7a869a;
  padding: 5px 10px;
}

.custom-modal {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  width: 25rem !important;
  min-height: 100vh !important;
  margin-top: 0 !important;
  border-left: 1px solid #dee2e6 !important;
}

.modal-content {
  min-height: 100vh !important;
  border: none !important;
  border-radius: 0 !important;
}

.modal {
  position: fixed !important;
  left: calc(100% - 25rem) !important;
  width: 25rem !important;
}
.modal::-webkit-scrollbar {
  display: none !important;
}

@media only screen and (max-width: 820px) {
  #sidebar {
    width: 100vw;
    overflow: visible;
    min-height: auto;
    transform: translateY(100%);
    bottom: 30px;
    top: 50%;
    transition: all 0.3s ease-in;
    border-bottom: none !important;
  }

  .modal {
    position: fixed !important;
    left: 0 !important;
    width: auto !important;
    overflow-y: hidden !important;
  }

  .sidebarCardCol {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 55vh !important;
    padding-top: 50px;
  }
  .sidebarActive {
    transform: translateY(-10%) !important;
    top: 40%;
  }
  .viewerContainer {
    height: 100vh;
    width: 100vw;
    transition: all 0.5s ease-in;
    margin-left: 0;
  }
  .custom-modal {
    position: relative !important;
    right: auto !important;
    top: auto !important;
    width: auto !important;
    margin-top: auto !important;
    border-left: none !important;
  }

  .modal-content {
    min-height: 70vh !important;
    border: initial !important;
    border-radius: 5px !important;
  }
}
